import React from "react"

import { Link } from "gatsby"

import * as styles from "./newFooter.module.css"

import logo from "../../../assets/images/logo.png"

const NewFooter = () => {
  return (
    <div className={styles.newFooter}>
      <Link to="#">
        <img src={logo} alt="logo" className={styles.logo} />
      </Link>
      <div>
        <p className={styles.footerTextGreen}>
          <span className={styles.footerText}>Humanize your meetings </span>{" "}
          with instant visuals
        </p>
      </div>
      <p className={styles.copyRight}>
        &#169; 2023 Tapestry Inc. All rights reserved.
      </p>
    </div>
  )
}

export default NewFooter
