import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Container from "../../reusable/container"

import * as styles from "./header.module.css"

import hamburger from "../../../assets/images/hamburger.svg"
import cross from "../../../assets/images/cross.png"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "1logo@3x 1.png" }) {
        childImageSharp {
          fixed(width: 256, height: 68) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const [showMenu, setShowMenu] = useState(false)
  return (
    <header className={styles.header}>
      <Container>
        <div className={styles.headerContent}>
          <Link to="/">
            <Img
              className={styles.logo}
              fixed={data.image.childImageSharp.fixed}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </Link>
          <div className={styles.pagesContainer}>
            <div className={styles.contents}>
              <Link to="https://blog.tapestryai.com">
                <span>Blog</span>
              </Link>
              <Link to="/about">
                <span>About us</span>
              </Link>

              {/* <button className={styles.signUpBtn}>
                <Link to="http://app.tapestryai.com/signup">
                  <span>Sign up</span>
                </Link>
              </button> */}
            </div>
            <div
              onClick={() => {
                setShowMenu(!showMenu)
              }}
              className={styles.hamburger}
            >
              <img src={hamburger} alt="hamburger"></img>
            </div>
            {showMenu && (
              <div className={styles.mobileContents}>
                <div className={styles.mobileLogoContainer}>
                  {" "}
                  <Link to="/">
                    <Img
                      className={styles.logo}
                      fixed={data.image.childImageSharp.fixed}
                      imgStyle={{
                        objectFit: "contain",
                      }}
                    />
                  </Link>
                  <button className={styles.crossBtn}>
                    <img
                      src={cross}
                      alt="cross btn"
                      onClick={() => {
                        setShowMenu(!showMenu)
                      }}
                    />
                  </button>
                </div>
                <div className={styles.hamburgerMenu}>
                  <Link to="/about">
                    <span>About us</span>
                  </Link>
                  <Link to="#">
                    <span>Blog</span>
                  </Link>
                  <button className={styles.signUpBtn}>
                    <Link to="http://app.tapestryai.com/signup">
                      <span>Sign up</span>
                    </Link>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </header>
  )
}

export default Header
