import React from "react"

import Container from "../../reusable/container"

import style from "./hero.module.css"

import ceo from "../../../assets/images/eileen.png"
import cto from "../../../assets/images/ramana.png"
import ben from "../../../assets/images/ben.png"
import gibron from "../../../assets/images/gibron.png"
import ellen from "../../../assets/images/ellen.png"
import jaden from "../../../assets/images/jaden.png"
import jean from "../../../assets/images/jean.png"
import joy from "../../../assets/images/joy.png"

const foundersData = [
  {
    name: "Eileen Clegg",
    designation: "Founder & CEO",
    about:
      "Pioneer in visual meeting facilitation over 20 years with Fortune 500 companies. Invented Tapestry Symbol System. Visual Journalist",
    img: ceo,
  },
  {
    name: "Ramana Rao",
    designation: "Founder & CTO",
    about:
      "@MIT @PARC technologist; Serial entrepreneur, Exits: @WaPo @Adobe @SAP CS/NLP/HCI Inventor/Researcher",
    img: cto,
  },
  {
    name: "Ben Mandeberg",
    designation: "Founding Engineer",
    about:
      "Creative technologist, full-stack engineer, interactive graphics. @Akamai @Google @Reddit",
    img: ben,
  },
  {
    name: "Gibron Kury",
    designation: "VP Engineering",
    about:
      "Hands-on Eng & Customer Manager in many strong product teams. @Adobe @Livefyre",
    img: gibron,
  },

  {
    name: "Ellen Lovelidge",
    designation: "UX Principal",
    about:
      "Interface designer, product design team leader, graphic artist, visual journalist.",
    img: ellen,
  },

  {
    name: "Jaden Rao",
    designation: "Product Artist",
    about: "Multi-media & digital artist, visual journalist.",
    img: jaden,
  },
  {
    name: "Jean Orlebeke",
    designation: "Advisor",
    about: "Design principal, creative director, graphic designer.",
    img: jean,
  },
  {
    name: "Joy Mountford",
    designation: "Advisor",
    about: "Silicon Valley pioneer and exec in HCI, design, user research.",
    img: joy,
  },
]
const AboutHero = () => {
  return (
    <div className={style.aboutHero}>
      <Container>
        <div className={style.contents}>
          <h1 className={style.aboutHeading}>About Us</h1>
          <h4 className={style.italicPara}>
            Tapestry’s founders Eileen Clegg and Ramana Rao are visualization
            pioneers who joined forces with a vision for leveraging imagery to
            facilitate communication and collaboration.
          </h4>
          <h6 className={style.paragraph}>
            Eileen has been a leader in live visual meeting facilitation for 20
            years, creating murals with phrases framed in metaphorical imagery
            for leadership conferences. Ramana has been a technology innovator
            for 30 years building sense-making and information visualization
            software including seminal online communication tools for newspapers
            and bloggers. Longtime friends, Ramana and Eileen brought together a
            diverse team of engineers, artists and designers they had worked
            with for many years prior, all of whom share a passion for helping
            people by humanizing technology.
          </h6>
          <div className={style.foundersAndEmployees}>
            {foundersData.map((item, index) => (
              <div key={index} className={style.employees}>
                <div className={style.pic}>
                  <img src={item.img} alt={item.name} />
                </div>
                <div className={style.details}>
                  <h4 className={style.name}>{item.name}</h4>
                  <h6 className={style.designation}>{item.designation}</h6>
                  <h6 className={style.about}>{item.about}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default AboutHero
