import React from "react"

import Layout from "../components/layout"
import AboutHero from "../components/about/hero"
import Footer from "../components/layout/newFooter"

const About = () => {
  return (
    <Layout>
      <AboutHero />
      <Footer />
    </Layout>
  )
}

export default About
